
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [''],
    },
  },
  setup(props) {
    const addOption = () => props.modelValue.push('')
    const deleteOption = (index: number) => {
      if (props.modelValue.length < 2) return

      props.modelValue.splice(index, 1)
    }

    return {
      addOption,
      deleteOption,
    }
  },
})
