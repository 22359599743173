import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64be490c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "preview__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", {
      class: _normalizeClass(['preview__title', {_empty: !_ctx.name.trim()}])
    }, _toDisplayString(_ctx.name.trim() || 'Field name'), 3),
    (_ctx.isDropdownSelected)
      ? (_openBlock(), _createBlock(_component_tm_field, {
          key: 0,
          modelValue: _ctx.previewValue.select,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.previewValue.select) = $event)),
          options: _ctx.dropdownOptions.map((opt, index) => ({value: index, text: opt || `Option ${index + 1}`})),
          type: "select",
          placeholder: `Select ${_ctx.nameAsPlaceholder || 'field name'}`
        }, null, 8, ["modelValue", "options", "placeholder"]))
      : _createCommentVNode("", true),
    (_ctx.isTextSelected)
      ? (_openBlock(), _createBlock(_component_tm_field, {
          key: 1,
          modelValue: _ctx.previewValue.str,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.previewValue.str) = $event)),
          type: "textarea",
          class: "distinct--text",
          placeholder: `Enter ${_ctx.nameAsPlaceholder || 'field name'}`,
          "one-line": ""
        }, null, 8, ["modelValue", "placeholder"]))
      : _createCommentVNode("", true),
    (_ctx.isDateSelected)
      ? (_openBlock(), _createBlock(_component_tm_field, {
          key: 2,
          modelValue: _ctx.previewValue.date,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.previewValue.date) = $event)),
          type: "datepicker",
          placeholder: "Select date"
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.isCheckboxSelected)
      ? (_openBlock(), _createBlock(_component_tm_field, {
          key: 3,
          modelValue: _ctx.previewValue.bool,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.previewValue.bool) = $event)),
          type: "checkbox",
          label: _ctx.description.trim() || 'Enter field description',
          class: _normalizeClass(['preview__checkbox-description', {_empty: !_ctx.description.trim()}])
        }, null, 8, ["modelValue", "label", "class"]))
      : _createCommentVNode("", true),
    (_ctx.isNumberSelected)
      ? (_openBlock(), _createBlock(_component_tm_field, {
          key: 4,
          modelValue: _ctx.previewValue.num,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.previewValue.num) = $event)),
          placeholder: `Enter ${_ctx.name || 'field name'}`,
          type: "number"
        }, null, 8, ["modelValue", "placeholder"]))
      : _createCommentVNode("", true)
  ]))
}