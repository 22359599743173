
import { defineComponent, ref, computed } from 'vue'
import { customFieldTypeConfigs } from '@/definitions/modals/shared/customField/data'

export default defineComponent({
  props: {
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    type: {
      type: Object,
    },
    dropdownOptions: {
      type: Array,
      default: () => [''],
    },
  },
  setup(props) {
    const previewValue = ref({
      str: '',
      select: '',
      date: '',
      bool: false,
      num: '',
    })
    const isDropdownSelected = computed(() => props.type?.text === customFieldTypeConfigs.dropdown.text)
    const isTextSelected = computed(() => props.type?.text === customFieldTypeConfigs.text.text)
    const isDateSelected = computed(() => props.type?.text === customFieldTypeConfigs.date.text)
    const isCheckboxSelected = computed(() => props.type?.text === customFieldTypeConfigs.checkbox.text)
    const isNumberSelected = computed(() => props.type?.text === customFieldTypeConfigs.number.text)

    const nameAsPlaceholder = computed(() => props.name.trim().toLowerCase())

    return {
      previewValue,
      isDropdownSelected,
      isTextSelected,
      isDateSelected,
      isCheckboxSelected,
      isNumberSelected,
      nameAsPlaceholder,
    }
  },
})
