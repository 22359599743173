
import { defineComponent, ref, computed } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import CustomFieldDropdownOptions from '@/components/modals/shared/customField/CustomFieldDropdownOptions.vue'
import CustomFieldPreview from '@/components/modals/shared/customField/CustomFieldPreview.vue'
import { customFieldTypeOptions, customFieldTypeConfigs } from '@/definitions/modals/shared/customField/data'

export default defineComponent({
  components: {
    TmFormLine,
    TmButton,
    TmModal,
    CustomFieldDropdownOptions,
    CustomFieldPreview,
  },
  props: {
    name: {
      type: String,
    },
    description: {
      type: String,
    },
    type: {
      type: Object,
      default: () => customFieldTypeOptions[0],
    },
    dropdownOptions: {
      type: Array,
      default: () => [''],
    },
    modalTitle: {
      type: String,
      default: 'New custom field',
    },
    view: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      default: 'Create',
    },
    viewText: {
      type: String,
      default: 'Contact details',
    },
    modalText: {
      type: String,
      default: 'Add new contact',
    },
  },
  setup(props) {
    const fieldName = ref(props.name)
    const fieldDescription = ref(props.description)
    const fieldType = ref(props.type)
    const options = ref(props.dropdownOptions)
    const isDropdownSelected = computed(() => fieldType.value?.text === customFieldTypeConfigs.dropdown.text)
    const isCheckboxSelected = computed(() => fieldType.value?.text === customFieldTypeConfigs.checkbox.text)

    const viewVisible = ref(props.view)
    const modalVisible = ref(props.modal)

    return {
      fieldName,
      fieldType,
      fieldDescription,
      customFieldTypeOptions,
      options,
      isDropdownSelected,
      isCheckboxSelected,
      viewVisible,
      modalVisible,
    }
  },
})
