import type { CustomFieldType, CustomFieldTypeConfig } from '@/definitions/modals/shared/customField/types'

export const customFieldTypeConfigs: Record<CustomFieldType, CustomFieldTypeConfig> = {
  text: {
    icon: 'text_fields',
    text: 'Text',
  },
  number: {
    icon: 'tmi-123',
    text: 'Number',
  },
  date: {
    icon: 'event',
    text: 'Date',
  },
  checkbox: {
    icon: 'check_box',
    text: 'Checkbox',
  },
  dropdown: {
    icon: 'tmi-form',
    text: 'Drop-down list',
  },
} as const

export const customFieldTypeOptions = Object.values(customFieldTypeConfigs)
